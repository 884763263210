<template>
  <div>
      <div class="mb-2">
          <b-button variant="primary-custom" @click="$router.go(-1)">
            <fa-icon class="mr-2" icon="arrow-left"></fa-icon>Voltar
          </b-button>
      </div>
      
      <TableList :data="bankAccounts" />
  </div>
</template>

<script>
export default {
    data(){
        return{
            unityId:this.$route.params.unityId
        }
    },
    components:{
        TableList:()=>import('./components/TableList')
    },
    computed:{
        bankList(){
            return this.$store.state.unities.bankList
        },
        bankAccounts(){

            let banks = this.$store.state.unities.bankAccounts

            _.forEach(banks, (bank)=>{
                const filteredBank = _.filter(this.bankList, (b)=>{
                    return b.id == bank.bank_id
                })
                bank.bankDetail = filteredBank[0]
            })

            return this.$store.state.unities.bankAccounts
        }
    },
    async mounted(){
        await this.$store.dispatch('unities/bank_accounts_init', this.unityId)
        console.log(this.applicationsProducts)
    }
}
</script>

<style>

</style>